<template>
  <div class="mini-tab-root tab-elevation1 tab-rounded" style=" width:100% !important; margin: 0 auto;" :class="`${tabCustom ? tabCustom : ''}`">
    <div class="mini-tab-content">
        <div class="mini-tab-scroller mini-tab-fixed" :style="`overflow: hidden;`">
          <div class="mini-tab-flex">
              <button style="width: 50%" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                <span style="margin-top: -1px;">
                  {{firstName ? firstName : 'Despesa'}}
                </span>
                <span class="MuiTouchRipple-root"></span>
              </button>
              <button style="width: 50%;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, tabPositionMax)">
                <span style="margin-top: -1px;">
                  {{lastName ? lastName : 'Receita'}}
                </span>
                <span class="MuiTouchRipple-root"></span>
              </button>
          </div>
          <span class="mini-tab-indicator" :class="`${miniTab.tab == 0 ? firstTabColor : lastTabColor}`" :style="`width: 50%; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}%;`"></span>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    tabIndex: {
      type: Number,
    },
    tabPositionMax: {
      type: String,
    },
    firstTabColor: {
      type: String,
    },
    lastTabColor: {
      type: String,
    },
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    tabCustom: {
      type: String,
    }
  },
  data() {
    return {
      miniTab: {
        tab: 0,
        indicator: 0,
      },
    };
  },
  mounted() {
  },
  created() {
    this.checkTab();
    this.$root.$on('slide_tab.change', this.changeSlideTab);
  },
  beforeDestroy() {
    this.$root.$off('slide_tab.change', () => {});
  },
  methods: {
    changeSlideTab(index) {
      console.log('Call tab', index);
      this.$forceUpdate();
      switch (index) {
        case 0:
          this.changeTab(0, 0);
          break;
        case 1:
          this.changeTab(1, this.tabPositionMax);
          break;
        default:
          break;
      }
    },
    checkTab() {
      this.$forceUpdate();
      switch (this.tabIndex) {
        case 0:
          this.changeTab(0, 0);
          this.changeTabCallBack(0);
          break;
        case 1:
          this.changeTab(1, this.tabPositionMax);
          this.changeTabCallBack(1);
          break;
        default:
          break;
      }
    },
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
      this.$emit('changeTabCallBack', tab);
    },
  },
};
</script>

<style lang="scss">
.tab-custom .mini-tab-indicator {
  background: #999 !important;
  border: 1px solid #7d7d7d !important;
  border-bottom: solid 3px #6c6c6c !important;
}
.tab-custom .mini-tab-content {
  height: 35px !important;
}
.tab-custom .mini-tab-button span{
  margin-top: -10px !important;
}
</style>
