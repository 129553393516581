<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-content">
            <div class="heading-title">
              <h2>Assinaturas</h2>
              <p>Essas são as suas assinaturas cadastradas em seus cartões.</p>
            </div>
          </div>
          <div class="slide-tab-header">
            <slide-tab tabPositionMax="50" firstName="Mês" lastName="Ano" tabCustom="tab-custom" @changeTabCallBack="changeTab"/>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="col-sm-12 hidden-lg hidden-md ">
        <button class="btn btn-success pull-right ml-5 action-income w49_5-m mBottom-10-m" type="button" @click="createTransaction('create-transaction-modal', 'income', false)"><span><i class="mdi mdi-plus-circle-outline"></i></span> Lançar receitas </button>
        <button class="btn btn-danger pull-right ml-5 action-income w49_5-m m-left-none-m mBottom-10-m" type="button" @click="createTransaction('create-transaction-modal', 'expense', false)"><span><i class="mdi mdi-minus-circle-outline"></i></span> Lançar despesa </button>
      </div>
      <div class="col-lg-12">
        <div class="owl-nav" style="position: relative; width: 35%; top: 0; margin: 0 auto; margin-top:20px;margin-bottom:23px;">
          <calendar-co :month="monthStart" :year="yearStart" :callBack="changeDate" :navigateTo="navigateDateTo"></calendar-co>
        </div>
      </div>
      <div class="col-sm-12 p-right-none" style="padding-left: 0;">
        <div class="form-row hide-m">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label>Filtro</label>
          </div>
        </div>
        <div v-for="(filter, n) in filters" class="form-row" :key="n">
          <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <select-input
              v-model="filters[n].field"
              :reduce="(field) => field.value"
              :options="fields"
              :clearable="true"
            />
          </div>
          <div class="form-group col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <select-input
              v-if="filters[n].field == 'all'"
              multiple
              :reduce="(field) => field.value"
              label="label"
              v-model="filters[n].values"
              :options="filterAll"
              :clearable="true"
              @input="filterRequest"
            />
            <select-input
              v-else-if="filters[n].field == 'card_id'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="cards"
              :clearable="true"
            />
            <select-input
              v-else-if="filters[n].field == 'account_id'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="wallets"
              :clearable="true"
            />
            <select-input
              v-else-if="filters[n].field == 'category'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="categories"
              :clearable="true"
            />
            <input
              v-else
              type="text"
              class="form-control"
              placeholder="Selecione um campo"
              disabled
            />
          </div>
          <div class="form-group col-lg-1 hide">
            <button
              @click="removeFilter(n)"
              class="btn btn-danger btn-sm"
              style="height: 40px;"
            >
              <i class="fas fa-trash color-white"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div>
          <div class="card-header">
            <h4>Total</h4>
          </div>
          <div class="card-body" style="padding-top: 0;">
            <section class="text-center">
              <h1 class="reports-expenses text-left font-w-600 ng-binding" style="margin-top: 0px;">{{subscription.total | currency }}</h1>
            </section>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content relative">
          <div class="cashflow-print" @click="print"><i class="fa fa-print hide"></i></div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-all'}" v-if="tabActive == 'tab-all'">
            <div class="card table-responsive longer" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="row hide">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="row">
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                        <label>Valor</label>
                        <input class="form-control currency ng-pristine ng-untouched ng-valid ng-empty" data-thousands="." data-decimal="," placeholder="0,00" name="value" ng-model="CashFlow.event.value">
                      </div>
                    
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                        <label>Tipo</label>
                        <select class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="type" ng-model="CashFlow.event.type_event"><option value="? undefined:undefined ?" selected="selected"></option>
                          <option value="income">Entrada</option>
                          <option value="expense">Saída</option>
                        </select>
                      </div>
                      <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4">
                        <label>Categoria</label>
                        <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="description" placeholder="Categoria" ng-model="CashFlow.event.description">
                      </div>
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                        <label>Data</label>
                        <input type="text" class="form-control datepicker-current ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" required="" past="true" placeholder="Data" name="due_date" ng-model="CashFlow.event.date">
                      </div>
                      <div class="col-sm-2 col-sm-4 col-lg-2 col-xs-2 text-right">
                        <br>
                        <button class="btn btn-primary btn-sm" style="width: 30%;padding-left: 20px !important;" ng-click="CashFlow.renderVar('')"><i class="fa fa-check"></i></button>
                        <button class="btn btn-danger btn-sm" style="width: 30%;padding-left: 20px !important;" ng-click="CashFlow.hideAdd()"><i class="fa fa-times"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="display-4">Transações</h3>
              <div class="row hide">
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                  Valor
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                  Tipo
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Categoria
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Status
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Conta/Cartão
                </div>
                <div class="col-sm-1 col-sm-1 col-lg-1 col-xs-1 col-xs-1 text-right">
                  Data
                </div>
              </div>
              <hr>
              <div class="row ng-scope" v-if="subscription.transactions.length == 0">
                <img :src="`assets/images/report.svg`" class="nothing-image w-15p" style="width: 10% !important">
                <h3 class="nothing-text">Nenhuma transação encontrada</h3>
              </div>
              <ul class="list-group" v-if="subscription.transactions">
                <li class="list-group-item ng-scope" v-for="(item, i) in subscription.transactions" :key="i">
                  <div class="row">
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-6">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-xs-6 text-right">
                      <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                      <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12">
                      <strong class="ng-binding" v-if="item.card">{{item.card.name }}</strong>
                    </div>
                    <div class="col-sm-3 col-lg-3 col-xs-12 m-p-top">
                      <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;">
                        <div v-tooltip.top="`${item.category == null  ? 'Sem categoria' : item.category.name}`" class="icon-category-overview" :style="`margin-right:0px;background: ${item.category ? item.category.color : '#ccc'}`">
                          <img v-if="item.category" :src="`/assets/images/icons/png/${ item.category.icon}.png`" />
                        </div>
                        &nbsp;<span class="hidden-sm visible-only-xs">{{item.category == null ? 'Sem categoria': item.category.name}}</span>
                      </span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12 text-right">
                      <span class="ng-binding">{{ item.transaction_date | moment("DD/MM/YYYY") }}&nbsp;&nbsp;</span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12 text-right" v-if="item.transaction_type != 'transfer'">
                      <button type="button" class="btn-edit-category" @click="editTransaction('edit-transaction-modal', item)">
                        <span>
                          <i class="fa fa-cog"></i>
                        </span>
                      </button>
                      <button type="button" class="btn-remove-category" @click="removeTransaction(item)">
                        <span>
                          <i class="fa fa-trash"></i>
                        </span>
                      </button>
                    </div>
                    <div class="col-sm-12 relative" v-if="item.description || item.automatic || item.provider || item.client" style="min-height: 28px;">
                      <hr class="hr-transactions">
                      <span class="ng-binding transaction-note" v-if="item.description">
                        <strong>Descrição</strong> {{item.description }}
                      </span>
                      <span class="ng-binding transaction-note" v-if="item.provider">
                        <strong>Fornecedor</strong> {{ item.provider.name }}
                      </span>
                      <span class="ng-binding transaction-note" v-if="item.client">
                        <strong>Cliente</strong> {{ item.client.name }}
                      </span>
                      <span class="label label-success " style="right: 15px; top: 15px; font-size: 10px; font-weight: 100; !important; position: absolute;" v-if="item.automatic">Automática</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import Swal from 'sweetalert2';
import CalendarCo from '../components/Calendar.vue';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import SlideTab from '@/components/SlideTab'
import SelectInput from '@/components/SelectInput.vue';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: 'Home',
  components: {
    SlideTab,
    CalendarCo,
    SelectInput,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      yearStart: 2024,
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      tabIncome: 'M',
      tabColor: '#ff1a1a',
      cards: [],
      categories: [],
      filters: [
        {
          field: '',
          values: [
          ],
        },
      ],
      fields: [
        { label: 'Cartões', value: 'card_id' },
        { label: 'Categoria', value: 'category' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      tabActive: 'tab-all',
      monthStart: 0,
      from: '',
      to: '',
      subscription: {
        total: 0,
        transactions: [],
      },
      isLoading: true,
    };
  },
  provide: {
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    this.yearStart = Number(moment(new Date()).format('YYYY'));
    pace.start();

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.request();

    AppService.getCards({
      from: this.from,
      to: this.to,
    }).then(
      (response) => {
        console.log('Cards');
        console.log(response);
        this.cards = response.cards;
        this.isLoadingCards = false;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );

    AppService.getCategories('Expense').then(
      (response) => {
        this.categories = response.categories;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );

    this.$root.$on('register.transaction', this.request);
  },
  methods: {
    navigateDateTo (month) {
      this.from = `${month.year}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.monthStart = month.index;
      this.request();
    },
    changeTab(tab) {
      this.miniTab.tab = tab;
      switch (tab) {
        case 0:
          this.tabIncome = 'M';
          break;
        case 1:
          this.tabIncome = 'A';
          break;
        default:
          break;
      }
      this.request();
    },
    createTransaction(id) {
      const data = {
        isCard: false,
        type: 'income',
        card: null,
        bill_type: {
          type: 'M',
        },
        is_recurrence: true,
        invoice_pay: false,
        invoice: null,
        account: null,
        date: moment(new Date()).format('DD/MM/YYYY'),
        check_sub: true,
      };
      this.$root.$emit('show.modal', id, data);
    },
    changeDate(date) {
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    request(requestType = null) {
      if (requestType) {
        this.tabIncome = requestType;
        if (requestType == 'A') {
          this.changeTab(1, 160);
        }
      }
      if(this.tabIncome == 'expense') {
        this.tabIncome = 'M';
      }
      AppService.getSubscriptions({
        from: this.from,
        to: this.to,
        type: this.tabIncome,
        filter: this.filters,
      }).then(
        (response) => {
          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
          this.isLoading = false;
          this.subscription = response.subscription;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
    editTransaction(id, item) {
      console.log('item', item);
      let data       = item;
      data.date      = moment(data.transaction_date, "YYYY-MM-DD").format('DD/MM/YYYY');
      data.value     = item.amount;
      data.note      = item.description;
      data.parent    = item.category.parent;
      data.isCard    = true;
      data.bill_type = {
        type: item.type,
      };
      this.$root.$emit('show.modal', id, data);
    },
    deleteTransaction(id) {
      AppService.deleteTransaction(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Transação removida!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeTransaction(item) {
      Swal.fire({
        title: `Remover transação?`,
        text: 'Tem certeza que deseja remover essa transação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteTransaction(item.id);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 200px;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.chart-legend-item {
  display: block;
}
.chart-legend-item .icon-category-overview {
  float: left;
}
.chart-legend-item .icon-category-overview {
  float: left;
}
.chart-legend-item span {
  float: left;
  display: block;
  margin: 2px;
}
.btn-edit-category {
  width: 40px !important;
}
.btn-remove-category {
  width: 40px !important;
}
.btn-pay-transaction {
  width: 40px !important;
}
.slide-tab-header{
  width: 50%;
  float: right;
}
@media (max-width: 547px) {
  .slide-tab-header{
    width: 100%;
  }
}
</style>
